import React from "react";
import "./DeviceBreakdownWidget.scss";
import WidgetHeader from "../WidgetHeader";
import WidgetGraph from "../WidgetGraph";

class DeviceBreakdownWidget extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {};
        this.dataType = "DELIVERED";
        this.title = 'Device Breakdown';
        if (this.props.graphType === 'group_breakdown') this.title = 'Site Breakdown';
        if (this.props.graphType === 'category_breakdown') this.title = 'Category Breakdown';
        if (this.props.id.indexOf('gas') !== -1) this.dataType += '_GAS';
        if (this.props.id.indexOf('gas_energy') !== -1) {
            this.dataType = 'GAS_ENERGY_CONSUMPTION'
        }
        if (this.props.id.indexOf('water') !== -1) this.dataType += '_WATER';
        if (this.props.id.indexOf('hot') !== -1) this.dataType += '_HOT';
		if (this.props.id.indexOf('generation') !== -1) {
			this.dataType = 'GENERATED_DELIVERED';
		}
        if (this.props.id.indexOf('export') !== -1) {
            this.dataType = 'EXPORTED_DELIVERED';
        }
    }

    render() {
        return (
            <div
                className={'widget ' + (!this.state.ready ? 'loading' : '') + (this.state.showLegend ? 'show-legend' : '')}>
                <WidgetHeader info={{
                    title: this.title,
                    type: this.props.type,
                    className: this.props.className,
                    icon: this.props.icon,
                    dataType: this.dataType,
                    color: this.props.color,
                    dateData: this.props.dateData,
                    assets: this.props.assets,
                    item: this.props.item,
                    graphType: 'bar',
                    widgetType: 'breakdown'
                }}/>
                <WidgetGraph graphType={this.props.graphType} showLegend={(val) => this.setState({showLegend: val})} dateData={this.props.dateData}
                             color={this.props.color}
                             granularity={this.props.dateData.end - this.props.dateData.start}
                             onLoad={() => this.setState({ready: true})} filterType={this.props.filterType} filterId={this.props.filterId}
                             average={this.props.title} dataTypes={[this.dataType]} dataItems={this.props.dataItems}
                             type={'breakdown'} id={this.props.id}/>
            </div>
        )
    }

}

export default DeviceBreakdownWidget;
