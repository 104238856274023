import React from "react";
import "./summary.scss";
import WidgetBoard from "./Components/WidgetBoard";
import GridDuck from "gridduck";
import * as _ from 'underscore';
import processedUrlParams from "../../services/processDataPageUrlParams";
import GenericLoader from "../../components/GenericLoader";
import {Button, FilterMenu, Icon, StateOptions, Tooltip} from "gd-react";
import LegendRange from "../../components/LegendRange";

class DesktopSummaryPage extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.widgetBoardUpdated = this.widgetBoardUpdated.bind(this);
		this.saveBoard = this.saveBoard.bind(this);
		this.cancelEditing = this.cancelEditing.bind(this);
		this.getWidgetBoardData = this.getWidgetBoardData.bind(this);
		this.onDateRangeUpdate = this.onDateRangeUpdate.bind(this);
		this.setFullScreen = this.setFullScreen.bind(this);
		this.setAvailableTelemetries = this.setAvailableTelemetries.bind(this);
		this.groupAssetsByType = this.groupAssetsByType.bind(this);
		this.initialLoad = this.initialLoad.bind(this);
		this.onCompareUpdate = this.onCompareUpdate.bind(this);
		this.checkTelemetryPending = this.checkTelemetryPending.bind(this)
		this.resetBoard = this.resetBoard.bind(this)
		this.boardRef = React.createRef();
		this.state = {
			editingWidgetBoard: false,
			boardData: null,
			loading: true,
			isPendingData: false,
			fullscreen: false
		}
	}

	snakeCase = string => {
		return string.replace(/\W+/g, " ")
			.split(/ |\B(?=[A-Z])/)
			.map(word => word.toLowerCase())
			.join('_');
	};

	resetBoard() {
		this.widgetBoard = null;
		this.widgetBoardId = null;
		this.setState({
			editingWidgetBoard: false,
			boardData: null,
			loading: true,
			fullscreen: false,
			assets: null,
			availableTelemetries: null
		}, () => {
			this.initialLoad();
		});
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.item && prevProps.item && this.props.item.id !== prevProps.item.id) {
			this.resetBoard();
		}
	}

	componentDidMount() {
		let self = this
		if (this.props.item) {
			this.initialLoad();
			if (this.props.item.id.indexOf('all') !== -1) {
				this.checkTelemetryPending().then((bool) => {
					self.setState({isPendingData: bool})
				})
			}
		}
	}


	componentWillUnmount() {
		GridDuck.abortAllRequests("Left Summary Page");
	}

	async initialLoad() {
		let dataItems = this.props.dataItems;

		// if (this.props.item.id.indexOf('all_') !== -1) {
		//     //When all site group selected get all the assets
		//     //FIXME Limited to 200 assets because it will crash otherwise
		//     let assets_in_hundreds_promises = [], number_of_hundreds_to_get = 2, item_cap = 100;
		//     for (let i = 0; i < number_of_hundreds_to_get; i++) {
		//         assets_in_hundreds_promises.push(GridDuck.getAssets({items: item_cap, offset: i * item_cap}))
		//     }
		//     let all_assets = await Promise.all(assets_in_hundreds_promises);
		//     dataItems = [];
		//     all_assets.forEach((aa) => {
		//         dataItems = dataItems.concat(aa.list);
		//     });
		// }

		// if (this.props.type === 'SiteGroup') {
		//     console.log('site group');
		//     dataItems = await this.getDevicesFromSiteGroup(this.props.item);
		//     console.log(dataItems, ' : assets from all sites');
		// }

		if (dataItems) {
			this.setState({
				assets: this.groupAssetsByType(dataItems),
				flatAssets: dataItems,
			});
		} else if (this.props.className === 'asset-page') {
			this.setState({
				assets: this.groupAssetsByType([this.props.item]),
				flatAssets: [this.props.item],
			});
		}
		this.widgetBoardId = this.props.item.widgetBoardId;

		if (this.widgetBoardId) {
			this.getWidgetBoardData();
		} else {
			this.setState({loading: false});
		}
	}

	groupAssetsByType(assets) {
		let returnAssets = {};
		let self = this;
		assets.forEach(function (asset) {
			asset.parent = asset.parentAsset;
			let stateOptions = StateOptions.find(t => t.type === asset.sku);
			if (stateOptions) {
				stateOptions.dataTypes.forEach(so => {
					let catArr = returnAssets[self.snakeCase(so.category)]
					if (!catArr) returnAssets[self.snakeCase(so.category)] = [];
					returnAssets[self.snakeCase(so.category)].push(asset);
					returnAssets[self.snakeCase(so.category)] = _.uniq(returnAssets[self.snakeCase(so.category)], x => x.id);
				})
			}
		});
		return returnAssets;
	}

	setAvailableTelemetries(assets) {
		let telems = [];
		let self = this;
		assets.forEach(function (asset) {
			let stateOptions = StateOptions.find(t => t.type === asset.sku);
			if (stateOptions) {
				stateOptions.dataTypes.forEach(function (dataType) {
					if ((!telems.find(dT => dT === dataType.category))) {
						if (dataType.category !== 'Signal') telems.push(self.snakeCase(dataType.category))
					}
				});
			}
		});
		return telems;
	}

	onDateRangeUpdate(start, end, a, granularity, rangeStringObj) {
		let wb = this.state.boardData;
		wb.dateData.start = start;
		wb.dateData.end = end;
		wb.dateData.d_rstring = rangeStringObj.dRString;
		wb.dateData.c_rstring = rangeStringObj.cRString;
		wb.dateData.compare_start = a ? a.start : null;
		wb.dateData.compare_end = a ? a.end : null;
		this.widgetBoardUpdated(wb, true, () => {
			this.saveBoard();
		});
	}

	onCompareUpdate(val) {
		// this.compareUpdate = true;
		// this.onDateRangeUpdate(this.state.boardData.start, this.state.boardData.end, null, this.state.boardData.granularity, {dRString: this.state.boardData.d_rstring, cRString: val})
	}

	async checkTelemetryPending() {
		let userData = await GridDuck.getAccount({id: GridDuck.userId})
		let filters = [
			{
				field: 'organisation_id',
				value: userData.id
			},
			{
				field: 'status',
				value: 'STARTED'
			},
		]
		let req = await GridDuck.getTelemetryOperations({filters: filters})
		return !!(req.list?.length)
	}

	getWidgetBoardData(doneEditing) {
		let self = this;
		let start, end, compareStart, compareEnd, granularity, dRString, cRString;
		return GridDuck.getWidgetBoard({id: this.widgetBoardId}).then(function (wb) {
			console.log(wb.columnData, " : columnData");
			// LEGACY FIX FOR PREVIOUS GAS WIDGET BOARDS
			Object.entries(wb.columnData).forEach(function ([id, column]) {
				console.log(column, ' : column for widget')
				column.widget_ids = column.widget_ids.map(widgetId => {
					if (widgetId.indexOf('gas') !== -1 && (widgetId.indexOf('volume') === -1 && widgetId.indexOf('energy') === -1)) {
						return widgetId.replace('gas', 'gas_volume');
					} else return widgetId;
				})
			})
			let dd = wb.dateData;
			let virtualURLParams = {
				get: function (str) {
					switch (str) {
						case 'dR':
							return dd.d_rstring;
							break;
						case 'cR':
							return dd.c_rstring;
							break;
						case 's':
							return dd.start;
							break;
						case 'e':
							return dd.end;
							break;
						case 'cE':
							return dd.compare_end;
							break;
						case 'cS':
							return dd.compare_start;
							break;
					}
				}
			};
			// let processed = processedUrlParams(virtualURLParams, dd.timezone ? dd.timezone : 'Europe/London');
			self.widgetBoard = wb;
			// wb._dateData.start = processed.start;
			// wb._dateData.end = processed.end;
			// if (processed.compareStart) {
			// 	wb._dateData.compare_start = processed.compareStart;
			// }
			// if (processed.compareEnd) {
			// 	wb._dateData.compare_end = processed.compareEnd;
			// }
			self.widgetBoardUpdated(wb, doneEditing);
		});
	}

	widgetBoardUpdated(boardState, doneEditing, cb) {
		let updateObj = this.state.boardData ? this.state.boardData : {};
		if (boardState.columnData) updateObj.columnData = boardState.columnData;
		if (boardState.columnOrder) updateObj.columnOrder = boardState.columnOrder;
		if (boardState.dateData) updateObj.dateData = boardState.dateData;
		let state = {
			boardData: updateObj,
			loading: false
		}
		if (doneEditing) state.editingWidgetBoard = false;
		this.setState(state, (data) => {
			if (cb) cb(data);
		})
	}

	cancelEditing() {
		return new Promise((resolve, reject) => {
			this.setState({loading: true, editingWidgetBoard: false, boardData: null}, () => {
				resolve();
				if (this.widgetBoardId) this.getWidgetBoardData(true);
				else {
					// window.setTimeout(() => {
					this.setState({loading: false});
					// }, 100);
				}
			});
		});
	}

	setFullScreen() {
		this.setState({hideFsButton: true}, () => {
			this.setState({fullscreen: !this.state.fullscreen, hideFsButton: false})
		})
	}

	saveBoard() {
		let site = this.props.item;
		let self = this;

		return new Promise((resolve, reject) => {
			self.setState({loading: true}, () => {
				if (!site.widgetBoardId) {
					GridDuck.createWidgetBoard({
						columnData: self.state.boardData.columnData,
						columnOrder: self.state.boardData.columnOrder,
						dateData: self.state.boardData.dateData,
						itemId: site.id === 'all' ? null : site.id
					}).then((res) => {
						self.widgetBoardId = res.id;
						self.getWidgetBoardData(true);
						resolve();
					});
				} else {
					if (self.widgetBoard) self.widgetBoard.set({
						columnData: self.state.boardData.columnData,
						columnOrder: self.state.boardData.columnOrder,
						dateData: self.state.boardData.dateData
					}).then((res) => {
						resolve();
						self.setState({editingWidgetBoard: false, loading: false});
					});

				}
			});
		})
	}

	render() {
		let self = this;
		return (
			<div className={'page narrow not-flex grey summary-page ' + (this.state.fullscreen ? 'fullscreen' : '')}>
				<div className={'widget-board-header'}>
					{!this.state.editingWidgetBoard ? <div style={{display: 'flex', alignItems: 'center'}}>
						<Button disabled={this.state.loading} color={'gd-grey'} label={'Edit'}
								outline
								onClick={(val) => this.setState({editingWidgetBoard: true})}/>
						<Tooltip label={'Refresh'}>
							<div className={'button-wrapper grey larger'}
								 style={{marginLeft: '10px'}}
								 onClick={this.cancelEditing}><Icon
								size={11}
								color={'#808080'}
								icon={'FaSync'}/>
							</div>
						</Tooltip>
						{!this.state.hideFsButton ?
							<Tooltip label={this.state.fullscreen ? 'Exit Fullscreen' : 'Fullscreen'}>
								<div className={'button-wrapper grey larger'}
									 style={{marginLeft: '10px'}}
									 onClick={this.setFullScreen}><Icon
									size={12}
									color={'#808080'}
									icon={this.state.fullscreen ? 'FaCompress' : 'FaExpand'}/>
								</div>
							</Tooltip> : null}
					</div> : null}
					<div style={{display: 'flex', alignItems: 'center'}}>
						{this.state.editingWidgetBoard ?
							<Button disabled={this.state.loading} color={'gd-grey'} additionalclasses={'sm'} progressRes
									label={'Cancel'}
									outline
									onClick={this.cancelEditing}/> : null}
						{this.state.editingWidgetBoard ?
							<Button disabled={this.state.loading} progressRes label={'Save'} additionalclasses={'sm'}
									onClick={this.saveBoard}/> : null}
					</div>
					<div style={{display: 'flex', alignItems: 'center'}}>
						{this.state.editingWidgetBoard ?
							<Button disabled={this.state.loading} style={{marginLeft: 0}} color={'gd-grey'}
									label={'Auto Layout'}
									outline
									onClick={this.boardRef.current.resetLayout}/> : null}
						{this.state.editingWidgetBoard && this.state.boardData.columnOrder.length < 10 ?
							<Button disabled={this.state.loading} color={'gd-grey'} label={'Add Column'}
									outline
									onClick={this.boardRef.current.addColumn}/> : null}
						{this.state.editingWidgetBoard ?
							<FilterMenu onUpdate={this.boardRef.current.onChangeFilter}
										filterItems={this.boardRef.current.state.filterOptions}>
								<Button disabled={this.state.loading} outline color={'gd-grey'}
										label={'Select Widgets'}/>
							</FilterMenu> : null}
					</div>

					{!this.state.editingWidgetBoard && this.state.boardData && this.state.boardData.dateData ?
						<LegendRange
							summaryMode
							disabled={this.state.loading}
							category={'Electricity'}
							dRString={this.state.boardData.dateData.d_rstring}
							cRString={this.state.boardData.dateData.c_rstring}
							granularity={this.state.boardData.dateData.granularity}
							onHover={this.state.boardData.dateData.onHover}
							onHide={this.state.boardData.dateData.onHide}
							resetZoom={this.state.boardData.dateData.resetZoom}
							zoomData={this.state.boardData.dateData.zoomData}
							defaultSelected={this.state.boardData.dateData.defaultSelected}
							graph={'bar'}
							graphType={this.state.boardData.dateData.graphType}
							start={this.state.boardData.dateData.start}
							time={this.state.boardData.dateData.time}
							timezone={this.state.boardData.dateData.timezone}
							end={this.state.boardData.dateData.end}
							set={this.onDateRangeUpdate}
							onIsComparingChange={() => {
							}}
							isAll={this.props.item.id.indexOf('all') !== -1}
							compareStart={this.state.boardData.dateData.compare_start}
							compareEnd={this.state.boardData.dateData.compare_end}
						/> : null}
				</div>
				{!this.state.loading && this.props.loaded ?
					<WidgetBoard timezone={this.props.item.timezone || 'Europe/London'}
								 ref={this.boardRef}
								 item={this.props.item}
								 type={this.props.type}
								 siteGroupString={this.props.siteGroupString}
								 reload={this.cancelEditing}
								 className={this.props.className}
								 filterType={this.props.filterType}
								 dataItems={this.props.dataItems}
								 filterId={this.props.filterId}
								 onCompareUpdate={this.onCompareUpdate}
								 fullscreen={this.state.fullscreen} setFullScreen={this.setFullScreen}
								 onDateRangeUpdate={this.onDateRangeUpdate} onCancel={this.cancelEditing}
								 widgetBoardId={this.widgetBoard ? this.widgetBoard.id : this.props.item.widgetBoardId}
								 isEditing={(val) => this.setState({editingWidgetBoard: val})}
								 boardData={this.state.boardData} saveBoard={this.saveBoard}
								 availableTelemetries={this.props.availableDataTypes}
								 isPendingData={this.state.isPendingData}
								 onChange={function (boardState, doneEditing, cb) {
									 self.widgetBoardUpdated(boardState, doneEditing, cb);
								 }} editing={this.state.editingWidgetBoard}/> : null}
				{this.state.loading || !this.props.loaded ?
					<GenericLoader text={'Fetching'} textLineTwo={'Widgets...'}/> : null}
			</div>
		);
	}
}

export default DesktopSummaryPage;