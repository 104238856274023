import React from "react";
import "./HistoricWidget.scss";
import {StateOptions} from "gd-react";
import WidgetHeader from "../WidgetHeader";
import WidgetGraph from "../WidgetGraph";

class HistoricWidget extends React.PureComponent {

    snakeCase = string => {
        return string.replace(/\W+/g, " ")
            .split(/ |\B(?=[A-Z])/)
            .map(word => word.toLowerCase())
            .join('_');
    };

    toTitleCase(str) {
        return str
            .split('_')
            .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    }

    constructor(props) {
        super(props);
        this.state = {};
        this.dataType = 'CONSUMPTION';
        if (this.props.id.indexOf('gas') !== -1 && this.dataType === 'CONSUMPTION') {
            this.dataType = 'GAS_PULSE';
        }
        if (this.props.id.indexOf('gas_energy') !== -1) {
            this.dataType = 'GAS_ENERGY_CONSUMPTION'
        }
        if (this.props.id.indexOf('hot') !== -1 && this.dataType === 'CONSUMPTION') {
            this.dataType = 'WATER_PULSE_HOT';
        }
        if (this.props.id.indexOf('water') !== -1 && this.dataType === 'CONSUMPTION') {
            this.dataType = 'WATER_PULSE';
        }
        if (this.props.id.indexOf('generation') !== -1) {
            this.dataType = 'GENERATED_CONSUMPTION';
        }
        if (this.props.id.indexOf('export') !== -1) {
            this.dataType = 'EXPORTED_CONSUMPTION';
        }

        if (this.props.widget.sensor) {
            this.dataType = this.props.widget.data_type.toUpperCase();
        }

        let range = this.props.dateData.end - this.props.dateData.start;
        this.gran = (range < 60) ? 60 : Math.floor((range / 60)/300) * 300;

    }

    render() {
        return (
            <div
                className={'widget ' + (!this.state.ready ? 'loading' : '') + (this.state.showLegend ? 'show-legend' : '')}>
                <WidgetHeader info={{
                    title: 'Historic',
                    type: this.props.type,
                    dataType: this.dataType,
                    icon: this.props.icon,
                    color: this.props.color,
                    dateData: this.props.dateData,
                    className: this.props.className,
                    item: this.props.item,
                    graphType: 'line',
                    widgetType: 'historic',
                    widget: this.props.widget
                }}/>

                <WidgetGraph showLegend={(val) => this.setState({showLegend: val})} dateData={this.props.dateData}
                             granularity={this.gran} color={this.props.color}
                             isPendingData={this.props.isPendingData}
                             onLoad={() => this.setState({ready: true})}
                             filterType={this.props.filterType}
                             filterId={this.props.filterId}
                             widget={this.props.widget}
                             average={(this.props.color === '#49b9c4')} dataTypes={[this.dataType]} dataItems={this.props.dataItems}
                             type={this.dataType === 'GENERATED' ? 'bar' : 'line'} id={this.props.id}/>
            </div>
        )
    }

}

export default HistoricWidget;
