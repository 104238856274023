import React from "react";
import "./HourOfDayWidget.scss";
import WidgetHeader from "../WidgetHeader";
import WidgetGraph from "../WidgetGraph";

class HourOfDayWidget extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {};
        this.dataType = "DELIVERED";
        if (this.props.id.indexOf('gas') !== -1) this.dataType += '_GAS'
        if (this.props.id.indexOf('gas_energy') !== -1) {
            this.dataType = 'GAS_ENERGY_CONSUMPTION'
        }
        if (this.props.id.indexOf('water') !== -1) this.dataType += '_WATER'
        if (this.props.id.indexOf('hot') !== -1) this.dataType += '_HOT'
        if (this.props.id.indexOf('generation') !== -1) {
            this.dataType = 'GENERATED_DELIVERED';
        }
        if (this.props.id.indexOf('export') !== -1) {
            this.dataType = 'EXPORTED_DELIVERED';
        }
    }

    render() {
        return (
            <div className={'widget ' + (!this.state.ready ? 'loading' : '') + (this.state.showLegend ? 'show-legend' : '')}>
                <WidgetHeader info={{
                    title: 'Hour of Day Average',
                    type: this.props.type,
                    className: this.props.className,
                    dataType: this.dataType,
                    icon: this.props.icon,
                    color: this.props.color,
                    dateData: this.props.dateData,
                    assets: this.props.assets,
                    item: this.props.item,
                    graphType: 'bar',
                    widgetType: 'hour_of_day'
                }}/>
                <WidgetGraph showLegend={(val) => this.setState({showLegend: val})} dateData={this.props.dateData}
                             aggregateBy={'hourOfDay'} granularity={60 * 60} color={this.props.color}
                             isPendingData={this.props.isPendingData}
                             onLoad={() => this.setState({ready: true})} filterType={this.props.filterType} filterId={this.props.filterId}
                             average={this.props.title} dataTypes={[this.dataType]} dataItems={this.props.dataItems}
                             type={'bar'} id={this.props.id}/>
            </div>
        )
    }

}

export default HourOfDayWidget;
